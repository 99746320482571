import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import scotlynn_logo from '../media/Scotlynn-Logo.png';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
          <div className="d-flex flex-column justify-content-center w-100" style={{height:'80vh'}}>
              <div className="d-flex flex-row justify-content-center w-100 h-100 p-3" >
                  <img src={scotlynn_logo} alt="Scotlynn Logo" style={{ height: '10vh', alignSelf:'center' }} />
              </div>
      </div>
             
       
          
        
    );
  }
}
